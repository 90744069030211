import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import * as loginActions from "../redux/actions/Inscription/index";
import { useDispatch } from "react-redux";
const Login = () => {
  const [checked, setChecked] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [password, setPassword] = useState(localStorage.getItem("password"));

  const login = async (email, password) => {
    if (checked === true) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    }
    var someDate = new Date(); // add arguments as needed
    someDate.setHours(someDate.getHours() + 15);
    localStorage.setItem("available", someDate);
    const response = await dispatch(loginActions.login({ email, password }));
    if (response) navigate("/");
  };

  const handleLogin = () => {
    login(email, password);
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label for="display-4">Adress Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="my-3">
                <label for="floatingPassword display-4">Mot de Passe</label>
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="my-3">
                <p>
                  Nouveau ici?{" "}
                  <Link
                    to="/register"
                    className="text-decoration-underline text-info"
                  >
                    Register
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <button
                  className="my-2 mx-auto btn btn-dark"
                  type="button"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
