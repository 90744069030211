import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../components/styles.css";
import AgeVerificationModal from "./AgeVerificationModal";

const Navbar = () => {
  const [isVerified, setIsVerified] = useState(undefined);

  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    if (ageVerified) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, []);

  const handleAgeVerification = (isAdult) => {
    if (isAdult) {
      localStorage.setItem("ageVerified", true);
      setIsVerified(true);
    } else {
      alert("Vous devez avoir 18 ans ou plus pour accéder à ce site.");
      window.location.href = "https://google.com"; // Redirect if underage
    }
  };

  const state = useSelector((state) => state.handleCart);
  const productList = useSelector((state) => state.produits.list);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value) {
      const filteredSuggestions = productList.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light py-3">
      <div className="container">
        <NavLink
          className="navbar-brand fw-bold fs-4 px-2 d-flex align-items-center"
          to="/"
        >
          <img
            src="https://ds-energy.s3.amazonaws.com/Logo.jpg"
            alt="DS Energy Logo"
            className="logo-img me-2"
          />
          <span>DS-Energy</span>
        </NavLink>

        <button
          className="navbar-toggler mx-2"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto my-2 text-center">
            <li className="nav-item">
              <NavLink className="nav-link" to="/product">
                Products
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/add_product">
                Add Products
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="search-bar position-relative">
            <i className="fa fa-search search-icon"></i>
            <input
              className="search-input"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchTerm}
              onChange={handleInputChange}
            />
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion._id}
                    onClick={() => navigate("/product/" + suggestion._id)}
                    style={{
                      display: "flex", // Use flexbox to align items in a row
                      cursor: "pointer", // Change cursor to pointer on hover
                      marginBottom: 10, // Add some padding for better spacing
                      marginLeft: -20,
                      marginTop: 5,
                      alignItems: "center", // Center items vertically
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    <img
                      src={suggestion.url}
                      style={{
                        height: 50,
                        width: 50,
                        marginRight: 5,
                        borderRadius: 15,
                      }}
                    />
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="buttons text-center">
            <NavLink to="/login" className="nav-linkk m-2">
              <i className="fa fa-user mr-1"></i> Se connecter/S'inscrire
            </NavLink>
            <NavLink to="/cart" className="nav-linkk m-2">
              <i className="fa fa-cart-shopping mr-1"></i> Panier (
              {state.length})
            </NavLink>
          </div>
        </div>
      </div>
      {isVerified === false && (
        <AgeVerificationModal onVerify={handleAgeVerification} />
      )}
    </nav>
  );
};

export default Navbar;
