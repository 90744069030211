import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use localStorage for web

console.log("createStore(rootReducer):", createStore(rootReducer()).getState());

const persistConfig = {
  key: "root",
  storage, // Use localStorage for web
};

export const configureStore = () => {
  const middlewares = [thunk];
  const composeEnhancers =
    process.env.NODE_ENV !== "production"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const persistedReducer = persistReducer(persistConfig, rootReducer());

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
