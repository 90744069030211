import * as types from "../types/types";
import jwt from "jwt-decode"; // import dependency

const initialState = {
  loading: false,
  autofocus: false,
  errors: false,
  list: [],
  user: {},
  secretaire: {},
  secretaireData: {},
  counter: 0,
  socket: {},
  token: "",
};
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INSCRIPTION:
      return { ...state, list: [...state.list, action.User] };

    case types.SOCKET:
      return { ...state, socket: action.socket };
    case types.LOGIN_REQUEST:
      return { ...state, loading: true };
    case types.LOGIN_FAILURE:
      return { ...state, loading: false };
    case types.LOGIN:
      return {
        ...state,
        token: action.user,
        user: jwt(action.user),
        secretaire: jwt(action.user).secretaireName,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.user,
        user: jwt(action.user),
        secretaire: jwt(action.user).secretaireName,
        loading: false,
      };

    case types.SMS_COUNTER:
      state.counter = action.counter;
      return { ...state };
    case types.AUTOFOCUS:
      state.autofocus = !state.autofocus;
      return { ...state };
    case types.UPDATE_USER:
      if (action.User.image !== "null") {
        state.user.image = action.User.image;
      }
      state.user.email = action.User.email;
      state.user.name = action.User.name;
      state.user.telephone = action.User.telephone;
      state.user.description = action.User.description;
      state.user.address = action.User.address;
      state.user.sexe = action.User.sexe;
      state.user.extension = action.User.extension;
      return { ...state };
    default:
      return state;
  }
};
export default AuthReducer;
