//user
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const ADD_USER = "ADD_USER";
export const ADD_CENTER_USER = "ADD_CENTER_USER";
export const TEMPERATURE = "TEMPERATURE";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const UPDATE_USER = "UPDATE_USER";
export const SOCKET = "SOCKET";
export const SMS_COUNTER = "SMS_COUNTER";
export const ACCEPT_USER = "ACCEPT_USER";
export const BLOQUER_USER = "BLOQUER_USER";
export const AUTOFOCUS = "AUTOFOCUS";

//produit
export const FETCH_PRODUIT_REQUEST = "FETCH_PRODUIT_REQUEST";
export const FETCH_PRODUIT_SUCCESS = "FETCH_PRODUIT_SUCCESS";
export const FETCH_PRODUIT_FAILURE = "FETCH_PRODUIT_FAILURE";
export const ADD_PRODUIT = "ADD_PRODUIT";
export const ADD_CENTER_PRODUIT = "ADD_CENTER_PRODUIT";
export const DELETE_PRODUIT = "DELETE_PRODUIT";
export const FETCH_PRODUIT_BY_ID = "FETCH_PRODUIT_BY_ID";
export const UPDATE_PRODUIT = "UPDATE_PRODUIT";

//inscription
export const INSCRIPTION = "INSCRIPTION";

export const FETCH_CENTER_PRODUIT = "FETCH_CENTER_PRODUIT";
export const LOGIN = "LOGIN";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//Commentaire
export const FETCH_COMMENTAIRE_REQUEST = "FETCH_COMMENTAIRE_REQUEST";
export const FETCH_COMMENTAIRE_SUCCESS = "FETCH_COMMENTAIRE_SUCCESS";
export const FETCH_COMMENTAIRE_FAILURE = "FETCH_COMMENTAIRE_FAILURE";
export const ADD_COMMENTAIRE = "ADD_COMMENTAIRE";
export const ADD_CENTER_COMMENTAIRE = "ADD_CENTER_COMMENTAIRE";
