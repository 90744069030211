import React from "react";
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">About Us</h1>
        <hr />
        <p className="lead text-center">
          Chez <b>DS-Energy</b>, notre engagement est de vous aider à atteindre
          vos objectifs de forme physique de manière motivante et enrichissante.
          Nous comprenons que chaque parcours de remise en forme est unique,
          c'est pourquoi nous offrons bien plus qu'un simple accès à des
          équipements de qualité. Notre équipe dévouée de professionnels de la
          remise en forme est là pour vous guider, vous inspirer et vous
          soutenir à chaque étape de votre voyage. En combinant des
          installations de pointe, des programmes personnalisés et une
          communauté bienveillante, nous créons un environnement propice à la
          transformation physique et mentale. Rejoignez-nous chez{" "}
          <b>DS-Energy</b> et découvrez comment nous faisons de chaque séance
          d'entraînement une expérience unique et stimulante, vous rapprochant
          un peu plus de votre meilleur vous chaque jour.
        </p>

        <h2 className="text-center py-4">Nos Produits</h2>
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://ds-energy.s3.amazonaws.com/About/Complement.webp"
                alt="Compléments"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Compléments</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://ds-energy.s3.amazonaws.com/About/Protein.webp"
                alt="Protéines"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Protéines</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://ds-energy.s3.amazonaws.com/Gym/Products/2024_09_03T09_12_44%20452755574_1005109934952990_2396096950081018925_n.jpg"
                alt="Vitamines"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Vitamines</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="https://ds-energy.s3.amazonaws.com/About/Snacks.webp"
                alt="Snacks"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Snacks</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
