import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Checkout = () => {
  const state = useSelector((state) => state.handleCart);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    country: "",
    state: "",
    zip: "",
  });

  // State to control button's disabled state
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Handle change for form inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Check if all required fields are filled
  useEffect(() => {
    const allFilled = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    setIsButtonDisabled(!allFilled);
  }, [formData]);

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Aucun article dans le panier</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Poursuivre vos achats
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const ShowCheckout = () => {
    let subtotal = 0;
    let shipping = 30.0;
    let totalItems = 0;
    state.map((item) => {
      return (subtotal += item.prix * item.qty);
    });

    state.map((item) => {
      return (totalItems += item.qty);
    });
    return (
      <>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">Récapitulatif de la commande</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Produits ({totalItems})
                      <span>{Math.round(subtotal)} TND</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Livraison
                      <span>{shipping} TND</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Montant Total</strong>
                      </div>
                      <span>
                        <strong>{Math.round(subtotal + shipping)} TND</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0">Adresse de facturation</h4>
                </div>
                <div className="card-body">
                  <form className="needs-validation" noValidate>
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label htmlFor="firstName" className="form-label">
                          Prénom
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          name="firstName"
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Un prénom valide est requis.
                        </div>
                      </div>

                      <div className="col-sm-6 my-1">
                        <label htmlFor="lastName" className="form-label">
                          Nom
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Un nom valide est requis.
                        </div>
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          autoComplete="email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Veuillez entrer une adresse e-mail valide pour les
                          mises à jour de livraison.
                        </div>
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="address" className="form-label">
                          Adresse
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          autoComplete="address-line1"
                          required
                          value={formData.address}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Veuillez entrer votre adresse de livraison.
                        </div>
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="phone" className="form-label">
                          Téléphone
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          autoComplete="tel"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-5 my-1">
                        <label htmlFor="country" className="form-label">
                          Pays
                        </label>
                        <select
                          className="form-select"
                          id="country"
                          name="country"
                          autoComplete="country"
                          required
                          value={formData.country}
                          onChange={handleChange}
                        >
                          <option value="">Choose...</option>
                          <option value="Tunisie">Tunisie</option>
                        </select>
                        <div className="invalid-feedback">
                          Veuillez sélectionner un pays valide.
                        </div>
                      </div>

                      <div className="col-md-4 my-1">
                        <label htmlFor="state" className="form-label">
                          Gouvernorat
                        </label>
                        <select
                          className="form-select"
                          id="state"
                          name="state"
                          autoComplete="address-level1"
                          required
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="">Choose...</option>
                          <option value="Ariana">Ariana</option>
                          <option value="Béja">Béja</option>
                          <option value="Ben Arous">Ben Arous</option>
                          <option value="Bizerte">Bizerte</option>
                          <option value="Gabès">Gabès</option>
                          <option value="Gafsa">Gafsa</option>
                          <option value="Jendouba">Jendouba</option>
                          <option value="Kairouan">Kairouan</option>
                          <option value="Kasserine">Kasserine</option>
                          <option value="Kébili">Kébili</option>
                          <option value="Le Kef">Le Kef</option>
                          <option value="Mahdia">Mahdia</option>
                          <option value="La Manouba">La Manouba</option>
                          <option value="Médenine">Médenine</option>
                          <option value="Monastir">Monastir</option>
                          <option value="Nabeul">Nabeul</option>
                          <option value="Sfax">Sfax</option>
                          <option value="Sidi Bouzid">Sidi Bouzid</option>
                          <option value="Siliana">Siliana</option>
                          <option value="Sousse">Sousse</option>
                          <option value="Tataouine">Tataouine</option>
                          <option value="Tozeur">Tozeur</option>
                          <option value="Tunis">Tunis</option>
                          <option value="Zaghouan">Zaghouan</option>
                        </select>
                        <div className="invalid-feedback">
                          Please provide a valid state.
                        </div>
                      </div>

                      <div className="col-md-3 my-1">
                        <label htmlFor="zip" className="form-label">
                          Code postal
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zip"
                          name="postalCode"
                          autoComplete="postal-code"
                          required
                          value={formData.zip}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Code postal requis.
                        </div>
                      </div>
                    </div>

                    <hr className="my-4" />

                    <button
                      className={`w-100 btn btn-primary ${
                        isButtonDisabled ? "disabledV2" : ""
                      }`}
                      type="submit"
                      style={{
                        cursor: isButtonDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      Confirmer la commande
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Paiement</h1>
        <hr />
        {state.length ? <ShowCheckout /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
