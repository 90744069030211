import React from "react";
import ContactPage from "./ContactPage";

const Footer = () => {
  return (
    <>
      <ContactPage />
    </>
  );
};

export default Footer;
