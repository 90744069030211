import React from "react";

const Home = () => {
  return (
    <>
      <div className="hero pb-3">
        <div className="card bg-dark text-white border-0">
          <img
            className="card-img img-fluid"
            src="./assets/Cover.jpg"
            alt="Card"
            height={500}
          />
          <div className="card-img-overlay d-flex align-items-center">
            <div className="container">
              <label>
                <h5 className="card-title fs-1 text fw-lighter">
                  DS Energy Pro
                </h5>
                <p className="card-text fs-5 d-none d-sm-block">
                  **Repoussez vos limites avec nos suppléments de fitness
                  premium.** Que vous visiez la croissance musculaire, l'énergie
                  optimale, ou une meilleure récupération, **DS Energy Pro**
                  vous offre l'innovation et la qualité pour atteindre vos
                  objectifs de santé et de performance.
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
