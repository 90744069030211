import handleCart from "./handleCart";
import produits from "./Produit";
import user from "./Auth-Reducer";
import users from "./User";

import { combineReducers } from "redux";

const rootReducer = () => {
  return combineReducers({
    produits,
    handleCart,
    user,
    users,
  });
};
export default rootReducer;
