import React from "react";
import {
  FaEnvelope,
  FaPhone,
  FaFacebook,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import "./styles.css";

export default function ContactPage() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <div className="p-8 bg-black rounded-lg shadow-lg w-full max-w-full mx-auto mt-10">
      <div className="flex flex-col lg:flex-row gap-10">
        {/* Text Section */}
        <div className="flex-1 lg:flex-none lg:w-1/3 text-white">
          <h2 className="text-3xl font-bold mb-6" style={{ color: "#ffec01" }}>
            Contactez-Nous !
          </h2>
          <p className="text-gray-300 mb-8">
            Nous sommes impatients d'avoir de vos nouvelles ! Que vous soyez
            intéressé à rejoindre notre communauté de fitness, à poser des
            questions sur nos cours, ou à en savoir plus sur nos programmes,
            nous sommes là pour vous aider. N'hésitez pas à nous contacter pour
            toute demande ou juste pour dire bonjour !
          </p>

          <div className="mb-6">
            <div className="flex items-center mb-4 space-x-4">
              <FaEnvelope className="text-3xl" style={{ color: "#ffec01" }} />
              <div className="flex flex-col">
                <span className="text-xl font-semibold">
                  Envoyez-nous un Email :
                </span>
                <a
                  href="mailto:Ghaithfit88@gmail.com"
                  className="yellow-ss mt-1"
                >
                  Ghaithfit88@gmail.com
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <FaPhone className="text-3xl" style={{ color: "#ffec01" }} />
              <div className="flex flex-col">
                <span className="text-xl font-semibold">
                  Appelez-nous Directement :
                </span>
                <a href="tel:+21621095583" className="yellow-ss mt-1">
                  +216 21 095 583
                </a>
              </div>
            </div>
          </div>

          <div className="text-gray-300 mb-6">
            <div className="flex space-x-6">
              <a
                href="https://www.facebook.com/dsenergytn"
                className="text-white hover:opacity-80 transition-opacity duration-300"
                aria-label="Facebook"
              >
                <FaFacebook className="w-8 h-8" style={{ color: "#ffec01" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UC..."
                className="text-white hover:opacity-80 transition-opacity duration-300"
                aria-label="YouTube"
              >
                <FaYoutube className="w-8 h-8" style={{ color: "#ffec01" }} />
              </a>
              <a
                href="https://www.instagram.com/ds_energytn/"
                className="text-white hover:opacity-80 transition-opacity duration-300"
                aria-label="Instagram"
              >
                <FaInstagram className="w-8 h-8" style={{ color: "#ffec01" }} />
              </a>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="flex-1 lg:w-2/3">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
              <input
                type="text"
                placeholder="Votre Nom"
                className="border border-gray-300 rounded-full p-4 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ffec01]"
                required
              />
              <input
                type="email"
                placeholder="Votre Email"
                className="border border-gray-300 rounded-full p-4 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ffec01]"
                required
              />
              <input
                type="text"
                placeholder="Votre Sujet"
                className="border border-gray-300 rounded-full p-4 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ffec01]"
                required
              />
            </div>

            <textarea
              placeholder="Votre Message"
              className="border border-gray-300 rounded-lg p-4 w-full h-32 mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-[#ffec01] resize-none"
              required
            ></textarea>

            <button
              style={{ backgroundColor: "#ffec01" }}
              type="submit"
              className=" rounded-lg py-3 px-6 font-semibold shadow-md transition duration-300"
            >
              ENVOYER LE MESSAGE
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
