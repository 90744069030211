import React, { useState } from "react";
import { Footer, Navbar } from "../components";
import { useDispatch } from "react-redux";
import * as produitActions from "../redux/actions/Produit/index";

const AddProduct = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState("");

  const handleAddProduit = async () => {
    if (checked === true) {
      setChecked(false);
    } else {
      const formData = new FormData();
      formData.append("photo", photo);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("prix", prix);
      formData.append("categorie", categorie);
      const x = await dispatch(produitActions.addProduit(formData));
    }
  };
  const [description, setDescription] = useState("");
  const [prix, setPrix] = useState("");
  const [categorie, setCategorie] = useState("");
  const [photo, setPhoto] = useState({});
  const [name, setName] = useState("");

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Ajouter Produit</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="form my-3">
                <label for="Name">Nom du Produit</label>
                <input
                  type="text"
                  className="form-control"
                  id="Title"
                  placeholder="Titre du produit"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form my-3">
                <label for="Description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="Description"
                  placeholder="Description du Produit"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="form  my-3">
                <label for="Prix">Prix</label>
                <input
                  type="text"
                  className="form-control"
                  id="Prix"
                  placeholder="prix du produit"
                  value={prix}
                  onChange={(e) => {
                    setPrix(e.target.value);

                    //data.push(e.target.value)
                  }}
                />
              </div>
              <div className="form my-3">
                <label htmlFor="Prix">Catégorie</label>
                <select
                  className="form-control"
                  id="Categorie"
                  value={categorie}
                  onChange={(e) => {
                    setCategorie(e.target.value);
                    // Additional logic if needed
                  }}
                >
                  {/* Add your select options here */}
                  <option value="" disabled>
                    Selectionner une Catégorie
                  </option>
                  <option value="Compléments">Compléments</option>
                  <option value="Protéines ">Protéines </option>
                  <option value="Vitamines">Vitamines</option>
                  <option value="Snacks">Snacks</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="fileUpload">Upload Image du Produit</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="fileUpload"
                  onChange={(e) => {
                    // Handle file upload logic
                    const selectedFile = e.target.files[0];
                    setPhoto(selectedFile);
                    // Additional logic if needed
                  }}
                />
              </div>
              <div className="text-center">
                <button
                  className="my-2 mx-auto btn btn-dark"
                  type="button"
                  onClick={() => handleAddProduit()}
                >
                  Ajouter Produit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddProduct;
