// src/serviceWorkerRegistration.js

// Function to register the service worker
export function register(config) {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      const swUrl = `https://aybranch.d2ilfrn5od7jon.amplifyapp.com/service-worker.js`;

      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log("Service Worker registered: ", registration);

          // Check for updates
          if (registration.waiting) {
            handleUpdate(registration);
          }

          registration.onupdatefound = () => {
            const newWorker = registration.installing;
            newWorker.onstatechange = () => {
              if (newWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  // New update available
                  console.log("New content is available; please refresh.");
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  // Content is cached for offline use
                  console.log("Content is cached for offline use.");
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error("Service Worker registration failed: ", error);
        });
    });
  }
}

// Function to unregister the service worker
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
}

// Function to handle updates
function handleUpdate(registration) {
  const newWorker = registration.waiting;
  if (newWorker) {
    newWorker.postMessage({ type: "SKIP_WAITING" });
  }
}
