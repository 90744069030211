import Axios from "axios";
import { message } from "antd";

export const inscription = async (user) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/signup",
      user
    );
    return result.data;
  } catch (err) {
    console.log(err);
    message.error("Adresse e-mail existante!");
  }
};

export const inscriptionFinal = async (user) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/signupfinal",
      {
        user,
      }
    );
    return result.data;
  } catch (err) {
    message.error("Le code a expiré");
  }
};

export const PasswordReset = async (user) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/password",
      {
        user,
      }
    );
    return result.data;
  } catch (err) {
    message.error("Le code a expiré");
  }
};

export const emailSender = async (email) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/email",
      {
        email,
      }
    );
    message.success("Un e-mail a été envoyé à votre adresse mail");
    return result.data;
  } catch (err) {
    message.error("Un problème est survenu, vérifiez vos informations");
  }
};

export const login = async (user) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/signin",
      user,
      {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          "Content-type": "application/json",
        },
      }
    );
    return result.data.token;
  } catch (err) {
    message.error(
      "Vous avez entré un nom d'utilisateur ou un mot de passe invalide"
    );
  }
};

export const sendEmail = async (email) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/sendemail",
      {
        email,
      }
    );
    message.success("E-mail envoyé avec succès");
    return result.data;
  } catch (err) {
    message.error("Une erreur s'est produite, réessayer plus tard");
  }
};

export const contactUs = async (email) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/contactus",
      {
        email,
      }
    );
    message.success("E-mail envoyé avec succès");
    return result.data;
  } catch (err) {
    message.error("Une erreur s'est produite, réessayer plus tard");
  }
};

export const sendQuestion = async (email) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/auth/sendquestion",
      {
        email,
      }
    );
    message.success("Votre demande a été envoyé avec succès");
    return result.data;
  } catch (err) {
    if (err.response.status === 401) {
      message.error("Compte existe deja!");
    } else message.error("Une erreur s'est produite, réessayer plus tard");
  }
};

let badpassword = false;

export { badpassword };
