import * as types from "../../types/types";
import * as api from "../../../services/auth-Service";

export const inscription = (user) => async (dispatch) => {
  const user1 = await api.inscription(user);

  dispatch({
    type: types.INSCRIPTION,
    user: user1,
  });
  return user1;
};

export const smsCounter = (counter) => async (dispatch) => {
  dispatch({
    type: types.SMS_COUNTER,
    counter,
  });
};

export const socket = (socket) => async (dispatch) => {
  dispatch({
    type: types.SOCKET,
    socket,
  });
};

export const inscriptionFinal = (user) => async (dispatch) => {
  const user1 = await api.inscriptionFinal(user);
  return user1;
};

export const login = (user) => async (dispatch) => {
  const user1 = await api.login(user);
  if (user1)
    dispatch({
      type: types.LOGIN,
      user: user1,
    });
  if (user1) return user1;
};

export const autofocus = (user) => async (dispatch) => {
  dispatch({
    type: types.AUTOFOCUS,
  });
};

export const loginNavbar = (user) => async (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST,
  });
  try {
    const user1 = await api.login(user);
    dispatch({
      type: types.LOGIN_SUCCESS,
      user: user1,
    });
  } catch (e) {
    dispatch({
      type: types.LOGIN_FAILURE,
    });
  }
};

export const PasswordReset = (user) => async (dispatch) => {
  const user1 = await api.PasswordReset(user);
  return user1;
};

export const emailSender = (email) => async (dispatch) => {
  const user1 = await api.emailSender(email);
  return user1;
};

export const sendEmail = (email) => async (dispatch) => {
  const user1 = await api.sendEmail(email);
  return user1;
};

export const sendQuestion = (email) => async (dispatch) => {
  const user1 = await api.sendQuestion(email);
  return user1;
};
