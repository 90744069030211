import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import { message, Progress } from "antd";
import { useDispatch } from "react-redux";
import * as authActions from "../redux/actions/Inscription/index";

const Register = () => {
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1200);
  const [checked, setChecked] = useState("");

  const [password_Strength, setPassword_Strength] = useState(0);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [characters, setCharacters] = useState(false);
  const [majuscul, setMajuscul] = useState(false);
  const [emailValide, setEmailValide] = useState("");
  const [passwordValide, setPasswordValide] = useState("");

  const addUser = async (data, email, password) => {
    const x = await dispatch(
      authActions.inscription({ data, email, password })
    );
    if (x) {
      message.info("Consulter votre boite mail pour terminer!", 10);
      setConfirmed(true);
    }
  };

  const handleAddUser = () => {
    if (checked !== true) {
      setChecked(false);
    }
    if (emailValide !== true) {
      setEmailValide(false);
    }
    if (!number || !special || !characters || !special) {
      setPasswordValide(false);
    }
    if (!emailValide) {
      message.error("Adresse e-mail invalide");
    } else if (!number || !special || !characters || !special) {
      message.error("Mot de passe est faible");
    }
    // else if (checked !== true) {
    //   message.error("Veuillez accepter notre politique de confidentialité!");
    // }
    else {
      addUser(name, email, password);
    }
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [suivant, setSuivant] = useState(false);
  const [disab, setDisab] = useState(true);

  const isValidPassword = (password) => {
    if (
      number === true &&
      special === true &&
      characters === true &&
      special === true
    ) {
      setPasswordValide(true);
    } else {
      setPasswordValide(false);
      message.warning("Veuillez vérifier votre mot de passe");
    }
  };

  const isValidEmailAddress = (address) => {
    if (!/\S+@\S+\.\S+/.test(address)) {
      message.warning("Veuillez vérifier votre adresse e-mail");
      setEmailValide(false);
    } else {
      setEmailValide(true);
    }
  };

  const checkStrength = (password) => {
    let strength = 0;

    //If password contains both lower and uppercase characters
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      setMajuscul(true);
    } else {
      setMajuscul(false);
    }
    //If it has numbers and characters
    if (password.match(/([0-9])/)) {
      strength += 1;
      setNumber(true);
    } else {
      setNumber(false);
    }
    //If it has one special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      setSpecial(true);
    } else {
      setSpecial(false);
    }
    //If password is greater than 7
    if (password.length > 7) {
      strength += 1;
      setCharacters(true);
    } else {
      setCharacters(false);
    }

    // If value is less than 2
    if (strength === 0) {
      setPassword_Strength(0);
    } else if (strength === 1) {
      setPassword_Strength(25);
    } else if (strength === 2) {
      setPassword_Strength(50);
    } else if (strength === 3) {
      setPassword_Strength(75);
    } else if (strength === 4) {
      setPassword_Strength(100);
    }
  };
  useEffect(() => {
    checkStrength(password);
  }, [password]);

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Register</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="form my-3">
                <label for="Name">Nom</label>
                <input
                  type="email"
                  className="form-control"
                  id="Name"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form my-3">
                <label for="Email">Adress Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={() => isValidEmailAddress(email)}
                />
              </div>
              <div className="form  my-3">
                <label for="Password">Mot de Passe</label>
                <input
                  type="password"
                  className="form-control"
                  id="Password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);

                    //data.push(e.target.value)
                  }}
                  onBlur={() => isValidPassword(password)}
                />
              </div>
              <div>
                <Progress
                  status={
                    password_Strength < 30
                      ? "exception"
                      : password_Strength < 80
                      ? "active"
                      : ""
                  }
                  showInfo={password_Strength === 100 ? true : false}
                  percent={password_Strength}
                />

                <ul className="list-unstyled">
                  <li
                    style={{
                      fontSize: isDesktop === true && "calc(0.7vw + 0.7vh)",
                    }}
                  >
                    {majuscul === true ? (
                      <i
                        style={{ color: "#02b502" }}
                        className="fas fa-check"
                      ></i>
                    ) : (
                      <i className="fas fa-circle"></i>
                    )}
                    &nbsp;Minuscule &amp; Majuscule
                  </li>
                  <li
                    style={{
                      fontSize: isDesktop === true && "calc(0.7vw + 0.7vh)",
                    }}
                  >
                    {number === true ? (
                      <i
                        style={{ color: "#02b502" }}
                        className="fas fa-check"
                      ></i>
                    ) : (
                      <i className="fas fa-circle"></i>
                    )}
                    &nbsp;Chiffre (0-9)
                  </li>
                  <li
                    style={{
                      fontSize: isDesktop === true && "calc(0.7vw + 0.7vh)",
                    }}
                  >
                    {special === true ? (
                      <i
                        style={{ color: "#02b502" }}
                        className="fas fa-check"
                      ></i>
                    ) : (
                      <i className="fas fa-circle"></i>
                    )}
                    &nbsp;Caractères Spéciaux (!@#$%^&*)
                  </li>
                  <li
                    style={{
                      fontSize: isDesktop === true && "calc(0.7vw + 0.7vh)",
                    }}
                  >
                    {characters === true ? (
                      <i
                        style={{ color: "#02b502" }}
                        className="fas fa-check"
                      ></i>
                    ) : (
                      <i className="fas fa-circle"></i>
                    )}
                    &nbsp;Au moins 8 caractères
                  </li>
                </ul>
              </div>

              <div className="my-3">
                <p>
                  Tu as déjà un compte ?{" "}
                  <Link
                    to="/login"
                    className="text-decoration-underline text-info"
                  >
                    Login
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <button
                  className="my-2 mx-auto btn btn-dark"
                  type="button"
                  onClick={() => handleAddUser()}
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
