import React from "react";

const ImageTextSplitLeft = ({ imageSrc, text }) => {
  const descriptionSegments = text.replace(/\./g, ".<br><br>");

  return (
    <div className="image-text-container">
      <div className="row flex-row-reverse" style={{ margin: 0 }}>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <div className="image-wrapper">
            <img src={imageSrc} alt="Visual" className="img-fluid" />
          </div>
        </div>
        <div className="col-md-6 align-items-center">
          <div
            style={{
              margin: "0", // Remove default margin
              boxSizing: "border-box", // Ensure padding is accounted for in width
              wordBreak: "break-word", // Break long words to prevent overflow
            }}
          >
            <label>
              <p
                className="my-4"
                dangerouslySetInnerHTML={{ __html: descriptionSegments }}
              ></p>
              <h3>Pourquoi Choisir Nos Suppléments?</h3>

              <ul>
                <li>
                  Formulation Scientifique: Chaque produit est élaboré pour
                  intégrer facilement ces suppléments dans votre routine
                  quotidienne.
                </li>
                <li>
                  Résultats Visibles: Profitez de résultats tangibles grâce à
                  une nutrition adéquate et à des produits de qualité.
                </li>
              </ul>

              <p>
                Nous sommes impatients de vous accueillir dans notre communauté
                de fitness. Que vous souhaitiez poser des questions sur nos
                cours ou en savoir plus sur nos programmes, n'hésitez pas à nous
                contacter pour toute demande ou juste pour dire bonjour !
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTextSplitLeft;
