import React, { useState } from "react";
import { Footer, Navbar } from "../components";
import { message } from "antd";
import { useDispatch } from "react-redux";
import * as authActions from "../redux/actions/Inscription/index";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode"; // import dependency

const Inscription = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(true);
  const [information, setInformation] = useState(false);

  const [number, setNumber] = useState("");

  const handleNumber = (value) => {
    if (value === jwt(id).result) {
      message.success("Code valide!", 3);
      setVerified(false);
    } else {
      setVerified(true);
    }
    setNumber(value);
  };

  const login = async (user) => {
    const user1 = await dispatch(authActions.inscriptionFinal(user));
    if (user1) {
      setInformation(true);
    }
  };

  const handleLogin = () => {
    login(id);
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Confirmation Code</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="form  my-3">
                <label for="Password">Code</label>
                <input
                  type="password"
                  className="form-control"
                  id="Password"
                  placeholder="Tapez Code"
                  value={number}
                  onChange={(e) => handleNumber(e.target.value)}
                />
              </div>
              <div className="text-center">
                <button
                  className={
                    verified === false
                      ? "my-2 mx-auto btn btn-dark"
                      : "disabled my-2 mx-auto btn btn-dark"
                  }
                  type="button"
                  onClick={() => {
                    dispatch(handleLogin);
                  }}
                >
                  Terminer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Inscription;
