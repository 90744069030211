// AgeVerificationModal.js
import React from "react";

function AgeVerificationModal({ onVerify }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Avez-vous 18 ans ou plus ?</h2>
        <button className="btn-yes" onClick={() => onVerify(true)}>
          Oui
        </button>
        <button className="btn-no" onClick={() => onVerify(false)}>
          Non
        </button>
      </div>
    </div>
  );
}

export default AgeVerificationModal;
